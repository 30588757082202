.index .Fanya_three_Desc {
    width: 100%;
    padding-bottom: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    overflow: hidden;
}
.index .Fanya_three_TT {
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: left;
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.index .doc{
    position: absolute;
    right: 10%;
    bottom: 5%;
    z-index: 99;
    width: 700px;
}
.index .bottom{
    width: 118px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #ff6a00;
    text-align: center;
    display: block;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    float: right;
    margin-right: 20px;
    background: #ff6a00;
}
.index .bottom a{
    color: #fff;
}
/*.index .bottom:hover {*/
/*    background-color: #db1b1b;*/
/*    border-color: #db1b1b;*/
/*    color: #fff;*/
/*}*/
.index .top{
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
    text-align: center;
}
