body {
    margin: 0 auto;
    background: url(../images/bg.png) repeat-x;
    background-color: #fff;
    font-size: 1rem;
    font-family: 'Microsoft YaHei New','微软雅黑','Microsoft Yahei','冬青黑体','Hiragino Sans GB','黑体','SimHei','华文细黑','STXihei',Arial,Helvetica Neue,Helvetica,sans-serif;
    color: #000000;
    user-select: none;
}
:focus{outline:0;}
a{
    text-transform: none;
    color:#666666;
    text-decoration: none;
    cursor: pointer;
}
a:hover {
    color: #ff6600;
    text-decoration: none;

}

a img{
    border:none;
}

form{
    margin:0;
    padding:0;
}

.blank_10{
    height:10px;
    font-size:1px;
}
.blank_15{
    height:15px;
    font-size:1px;
}
.blank_20{
    height:20px;
    font-size:1px;
}
.blank_27{
    height:27px;
    font-size:1px;
}
.blank_30{
    height:30px;
    font-size:1px;
}
.blank_40{
    height:40px;
    font-size:1px;
}
.blank_50{
    height:50px;
    font-size:1px;
}

.blank_56{
    height:56px;
    font-size:1px;
}

.blank_60{
    height:60px;
    font-size:1px;
}

.clear{
    clear:both;
    font-size:0;
}
.all_width {
    width: 100%;
    height: 108px;
    position: absolute;
    top: 50px;
    left: 0;
    box-shadow: 0 12px 12px rgba(0,0,0,0.08);
    z-index: 99999999;
    background-color: #fff;
}
.warp{
    width:1360px;
    margin:0 auto;
}
.service-details{
    overflow: hidden;
}
.vub{
    /*width:1360px;*/
    height:50px;
    position:fixed;
    width: 100%;
    top: 0;
    z-index: 99999;
    background: #f0f3fc;
}
.vub_A{
    width:190px;
    height:50px;
}
.vub_A ul{
    margin:0;
    padding:0;
    list-style:none;
}
.vub_A ul li{
    width:95px;
    height:50px;
    float:left;
    background:url(../images/sub_line.png) right center no-repeat;
    position:relative;
}
.vub_A ul li a{
    width:94px;
    height:50px;
    line-height:50px;
    text-align:center;
    position:absolute;
    left:0;
    top:0;
    font-size:16px;
    color:#0c2166;
    text-decoration:none;
}
.vub_A ul li:hover a{
    height:47px;
    line-height:47px;
    border-bottom:3px solid #0c2166;
}

.vub_B{
    /*width:130px;*/
    height:50px;
    line-height:50px;
    right:0;
    top:0;
    text-align:right;
    font-size:16px;
    color:#fff;
    font-weight:bold;
    background-color:#db1b1b;
    padding: 0 15px;
    float: right;
    position: absolute;
}
.fixed{
    width:100%;
    height:108px;
}
.banner{ position:relative; overflow:hidden; height:499px; width:100%; z-index:99;}
.index_banner{ width:2560px; position:relative; height:499px; overflow:hidden;left:50%; margin-left:-1280px;}
.index_ban_ul{ width:2560px; height:499px; overflow:hidden; z-index:0;position:relative; margin:0; padding:0; list-style:none; }
.index_ban_num{ width:100%;  position:absolute; bottom:20px; text-align: center; z-index:1;font-size:0; padding:0; }
.index_ban_num li{margin: 0 4px; background: url(../images/ban_li_ico.png) no-repeat; width: 12px; height: 12px; overflow: hidden; cursor: pointer;display:inline-block;  *display:inline; zoom:1;}
.index_ban_num li:hover,.index_ban_num .on{ background: url(../images/ban_cur_ico.png) no-repeat;}
.header{
    width:1360px;
    height:108px;
    position:relative;
}

.logo{
    /*width:272px;*/
    height:108px;
    position:absolute;
    left:0;
    top:0;
    line-height: 51px;
    font-size: 32px;
}
.new_nav{
    width:890px;
    height:108px;
    position:absolute;
    left:500px;
    top:0;
}
.new_nav ul{
    margin:0;
    padding:0;
    list-style:none;
}
.new_nav ul li{
    float:left;
    padding:0 22px;
    height:108px;
    line-height:108px;
    position:relative;
}
.new_nav ul li>a{
    font-size:18px;
    color:#1a1a1a;
    font-weight:900;
    text-decoration:none;
}

.new_nav ul li.m{
    background:url(../images/nav_open.png) right 50px no-repeat;
    padding-right:19px;
}

.new_nav ul li.m:hover{
    background:url(../images/nav_off.png) right 50px no-repeat;
}

.new_nav ul li span{
    padding-right:19px;
    height:106px;
    line-height:106px;
    display:block;
    background:url(../images/nav_open.png) right 50px no-repeat;
    font-size:18px;
    cursor:pointer;
    font-weight:900;
}
.new_nav ul li span.active{
    background:url(../images/nav_off.png) right 50px no-repeat;
    color:#0c2166;
    font-weight:bold;
    border-bottom:2px solid #0c2166;
}

.nav_sub{
    width:100%;
    height:112px;
    background-color:#fff;
    position:absolute;
    left:11px;
    top:108px;
    display:none;
    margin:0;
    padding:0;
    list-style:none;
    box-shadow:0 0 6px rgba(0,0,0,0.2);
}
.nav_sub dt{
    width:100%;
    height:56px;
    float:left;
    line-height:56px;
    text-align:center;
    border-bottom:1px solid #c7c7c7;
}
.nav_sub dt:last-child{
    border:none;
}
.nav_sub dt a{
    font-size:18px;
    color:#1a1a1a;
    font-weight:bold;
    text-decoration:none;
}

.nav_sub dt:hover{
    background-color:#f0f0f0;
}

.new_drop-down{
    width:1360px;
    height:569px;
    position:absolute;
    left:-532px;
    top:108px;
    background-color:#fff;
    box-shadow:0 0 6px rgba(0,0,0,0.2);
    display:none;
    z-index:99999999999999;
}
.all_Fanya_three{
    width:100%;
    padding-top:40px;
    padding-bottom:18px;
    background-color:#fff;
    overflow:hidden;
}
.Fanya_three_TT{
    width:100%;
    height:70px;
    line-height:70px;
    text-align:center;
    font-size:40px;
    color:#0c2166;
    font-weight:bold;
}
.Fanya_three_Desc{
    width:100%;
    padding-bottom:24px;
    line-height:24px;
    text-align:center;
    font-size:18px;
    color:#1a1a1a;
    overflow:hidden;
}

.Fanya_csr-left{ position:relative; width:1500px; height:422px; margin:0 auto;}
.Fanya_csr-left .hd{height:55px; width:1500px;position:absolute; left:0; top:204px; z-index:2;}
.hd_xi{ width:1500px;height:55px;position:relative;}
.Fanya_csr-left .hd .prev{ display:block; width:55px; height:55px;overflow:hidden; cursor:pointer; background:url(../images/roll_l.png) no-repeat; position:absolute; left:0; top:0; display:none;}
.Fanya_csr-left .hd .prev:hover{background:url(../images/roll_ls.png) no-repeat;}
.Fanya_csr-left .hd .next{display:block; width:55px; height:55px; overflow:hidden; cursor:pointer; background:url(../images/roll_r.png) no-repeat;position:absolute; right:0; top:0;display:none;}
.Fanya_csr-left .hd .next:hover{background:url(../images/roll_rs.png) no-repeat;}
.Fanya_csr-left .hd .prevStop{ background-position:-60px 0; }
.Fanya_csr-left .hd .nextStop{ background-position:-60px -50px; }
.Fanya_csr-left .hd ul{ overflow:hidden; zoom:1; margin:0; padding:0; list-style:none; position:absolute; left:710px; top:276px;}
.Fanya_csr-left .hd ul li{ float:left;  width:10px; height:10px; overflow:hidden; margin-right:7px;cursor:pointer; background-color:#cccccc; text-align:center; font-size:0;}
.Fanya_csr-left .hd ul li.on{background-color:#db1b1b;}
.Fanya_csr-left .bd{ width:1384px; overflow:hidden;z-index:1; position:absolute; left:57px;}
.Fanya_csr-left .bd ul{overflow:hidden;zoom:1; margin:0; padding:0; list-style:none; }
.Fanya_csr-left .bd ul li{ width:320px;float:left; height:370px;_display:inline; margin:13px;border-radius:10px; overflow:hidden; background-color:#f0f3fc;}

.pageState{
    display:none;
}
.Fanya_csr_five{
    width:320px;
    height:370px;
    position:relative;
}
.Fanya_csr_fiveA{
    width:320px;
    height:136px;
    position:absolute;
    left:0;
    top:0;
    overflow:hidden;
}
.Fanya_csr_fiveB{
    width:284px;
    height:40px;
    line-height:30px;
    font-size:22px;
    color:#0c2166;
    position:absolute;
    left:18px;
    top:162px;
    font-weight:bold;
    text-align:center;
}
.Fanya_csr_fiveD{
    width:284px;
    height:46px;
    line-height:22px;
    font-size:14px;
    color:#1a1a1a;
    position:absolute;
    left:18px;
    top:207px;
    text-align:center;
    overflow:hidden;
}
.Fanya_csr_fiveE{
    width:98px;
    height:38px;
    line-height:38px;
    position:absolute;
    left:110px;
    top:273px;
    border:1px solid #0c2166;
    color:#0c2166;
    font-size:12px;
    border-radius:40px;
    text-align:center;
    font-weight:bold;
}

.Fanya_csr-left .bd ul li:hover{
    background-color:#fff;
    box-shadow:0 0 12px rgba(0,0,0,0.2);
}
.Fanya_csr_five:hover .Fanya_csr_fiveA img{
    transform: scale(1.1);
}

.Fanya_csr_fiveA img{
    transition:all 0.3s linear;
    -webkit-transition:all 0.3s linear;
    -moz-transition:all 0.3s linear;
    -o-transition:all 0.3s linear;
}

.Fanya_csr_five:hover .Fanya_csr_fiveE{
    background-color:#db1b1b !important;
    border-color:#db1b1b !important;
    color:#fff !important;
}
.Fanya_csr-left .tempWrap{
    overflow:hidden; position:relative; width:1384px
}
.Fanya_csr-left .infoList{
    width: 3114px; left: 0px; position: relative; overflow: hidden; padding: 0px; margin: 0px;
}
.Fanya_csr-left .infoList li{float: left; width: 320px;}

.all_fanya_zs .tempWrap{
    overflow:hidden; position:relative; width:1200px
}
.all_fanya_zs .piclist{
    width: 3000px; position: relative; overflow: hidden; padding: 0px; margin: 0px; left: -300px;
}
.all_fanya_zs .piclist li{
    float: left; width: 300px;
}
.all_fanya_zs{
    width:100%;
    padding:0px 0 100px 0;
    overflow:hidden;
}
.fanya_zs{ position:relative;zoom:1; height:410px; width:1360px; margin:0 auto;}
.fanya_zs .piclist{ overflow:hidden; zoom:1; height:426px; width:1320px; margin:0; padding:0; list-style:none;}
.fanya_zs .ohbox{overflow:hidden; position:relative;left: 65px;width:1200px;}
.fanya_zs .piclist li{float:left;width:300px; height: 426px;}
.fanya_zsx{
    width:300px;
    height:426px;
    position:relative;
}
.fanya_zsx_A{
    width:266px;
    height:362px;
    position:absolute;
    left:12px;
    top:12px;
    padding:6px;
    background-color:#fff;
    text-align:center;
    box-shadow:0 0 12px rgba(0,0,0,0.2);
    border-radius:10px;
    overflow:hidden;
}
.fanya_zsx_A img{
    width:266px;
    height:362px;
}

.fanya_zsx_B{
    width:278px;
    height:52px;
    line-height:52px;
    position:absolute;
    left:0;
    top:386px;
    font-size:16px;
    color:#1a1a1a;
    font-weight:bold;
    text-align:center;
}

.fanya_mzs{
    width:100%;
    overflow:hidden;
}
.fanya_mzs ul{
    margin:0;
    padding:0;
    list-style:none;
}
.fanya_mzs ul li{
    width:50%;
    float:left;
}
.fanya_mzsx{
    width:94%;
    margin:0 auto;
}
.fanya_mzsx_A{
    width:100%;
    background-color:#fff;
    text-align:center;
    border:1px solid #ddd;
    overflow:hidden;
}
.fanya_mzsx_A img{
    width:100%;
    height:auto;
}

.fanya_mzsx_B{
    width:100%;
    height:42px;
    line-height:42px;
    font-size:14px;
    color:#1a1a1a;
    text-align:center;
}

.clear{
    clear:both;
}

.flip-box {
    position: relative;
    width: 300px;
    height: 426px;
    perspective: 1000px;
}
.flip-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}
.flip-front, .flip-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}
.flip-back {
    transform: rotateY(180deg);
}
.flip-box:hover .flip-inner {
    transform: rotateY(180deg);
}
.fanya_zs .pageBtn span{display:block;width:55px;height:360px;position:absolute;top:0;cursor:pointer;overflow:hidden; text-indent:-9999px; left:0px;}
.fanya_zs .pageBtn .prev{background:url(../images/hot_l.png) left center no-repeat;}
.fanya_zs .pageBtn .next{background:url(../images/hot_r.png) right center no-repeat; left:1280px;}
.fanya_zs .pageBtn span:hover{background:url(../images/hot_ls.png) left center no-repeat;}
.fanya_zs .pageBtn .next:hover{background: url(../images/hot_rs.png) right center no-repeat;}
.all_fanya_cc{
    width:100%;
    height:398px;
    /*background:url(../images/all_cc.jpg) center top no-repeat;*/
    overflow:hidden;
}
.fanya_cc{
    min-width:1550px;
}
.fanya_cc ul{
    margin:0;
    padding:0;
    list-style:none;
}
.fanya_cc ul li{
    width:50%;
    float:left;
}
.fanya_cc_li{
    width:100%;
    height:398px;
    position:relative;
}
.fanya_cc_lia{
    width:67px;
    height:44px;
    position:absolute;
    left:0;
    top:56px;
}
.fanya_cc ul li:first-child{
    background:url(../images/haishui.jpg) center top no-repeat ;
    background-size: 100% 100%;
}
.fanya_cc ul li:last-child{
    background:url(../images/fanghuo.jpg) center top no-repeat ;
    background-size: 100% 100%;
}
.fanya_cc_lib{
    width:580px;
    height:56px;
    line-height:56px;
    font-size:36px;
    color:#fff;
    font-weight:bold;
    position:absolute;
    left:28.5%;
    top:74px;
}
.fanya_cc_lic{
    width:60%;
    height:auto;
    color:#fff;
    font-size:16px;
    line-height:24px;
    position:absolute;
    left:28.5%;
    top:133px;
}
.fanya_cc_lid{
    width:120px;
    height:40px;
    position:absolute;
    left:28.5%;
    top:265px;
    cursor: pointer;
}
.fanya_cc_lid a{
    width:118px;
    height:38px;
    line-height:38px;
    border:1px solid #fff;
    text-align:center;
    display:block;
    border-radius:20px;
    font-size:14px;
    color:#fff;
}
.fanya_cc_lid a:hover{
    background-color:#db1b1b;
    border-color:#db1b1b;
    color:#fff;
}
.l100{
    left: 100px !important;
}
 .bm-main {
    width: 100%;
     height: 420px;
}
.bm-main div, .bm-main  input, .bm-main  li, .bm-main  span, .bm-main  ul, .bm-main  h5 {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
 .bm-main .bm-main-ul .bm-main-li a {
    width: 1200px
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-left {
    position: relative;
    width: 300px;
    height: 400px;
    padding: 30px;
    /*background: #fff;*/
    font-size: 14px;
     /*box-shadow: 0 0 12px rgba(0,0,0,0.2);*/
     background: #f0f3fc;
     border-radius: 5px;
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-left a {
    width: 100%
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-left h5 {
    font-size: 24px;
    font-weight: 700;
    color: #333
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-left img {
    width: 240px;
    height: 166px;
    margin: 30px 0 20px
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-left p {
    line-height: 23px;
    color: #999
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-left span {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: #4370f5;
    cursor: pointer
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right {
    width: 890px
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right ul {
    flex-wrap: wrap
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li {
    width: 220px;
    height: 195px;
    padding: 20px;
    /*background: #fff;*/
    margin-bottom: 10px;
     background: #f0f3fc;
     border-radius: 5px;
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li:nth-child(5), .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li:nth-child(6), .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li:nth-child(7), .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li:nth-child(8) {
    margin-bottom: 0
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li h5 {
    line-height: 23px;
    font-size: 18px;
    color: #333;
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li p {
    margin: 15px 0 10px;
    line-height: 23px;
    font-size: 14px;
    color: #999
}

 .bm-main .bm-main-ul .bm-main-li .bm-main-right ul li img {
    width: 100%;
    height: 92px
}
.n_list1{display: flex;flex-wrap: wrap}
.n_item1{height: 334px; margin-right: 40px;margin-top: 40px;width: calc((100% - 80px) / 3);background: #f0f2fc; border-radius: 8px; border-bottom-right-radius: 0}
.n_item1:nth-child(3n){margin-right: 0}
.n_item1:nth-child(-n+3){margin-top: 0}
.n_item1 a{display: block;padding:50px 30px 0 30px; }
.n_item1 .icon{height: 100px;font-size: 100px;line-height: 100px; display: block;
    background-image:-webkit-linear-gradient(top,  #43c5ed,#303360);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;}
.n_item1 .title{position: relative;margin-top: 30px; padding-right: 20px;font-size: 22px;color: #000;display: block;transition: .4s all ease}
.n_item1 .title .img{position: absolute;right: 0;top: 4px;color: #525252;;transition: .4s all ease}
.n_item1 p{margin-top: 24px;height: 72px;line-height: 36px;font-size: 16px;color: #525252;
    overflow : hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.n_item1:hover .title{color: #e07405}
.n_item1:hover .title .img{color: #e07405}
.list_921{ display: flex; flex-wrap: wrap;}
.list_921 .item{width:calc((100% - 60px)/3); margin-bottom:30px;}
.list_921 .item:not(:nth-child(3n)){margin-right:30px;}
.list_921 .item a{position:relative; display: block; overflow: hidden;}
.list_921 .item a .img{display:block;  transition: all .3s ease; background-size:cover; background-position: center center; background-repeat: no-repeat;}
.list_921 .item a .title{position:absolute; bottom: 0; left: 0; width:100%; padding: 10px 0; background: rgba(0,0,0,0.65); color: #fff; z-index:2; font-size:16px; text-align: center; transition: all .3s ease;}
.list_921 .item a:hover .img{ transform:scale(1.1);}
.list_921 .item a:hover .title{ background:rgba(0,0,0,0.75)}
.item:hover .hover{
    display: block;
}.item:hover .title{
     display: none;
 }
/*h5 {*/
/*    display: block;*/
/*    font-size: 0.83em;*/
/*    margin-block-start: 1.67em;*/
/*    margin-block-end: 1.67em;*/
/*    margin-inline-start: 0px;*/
/*    margin-inline-end: 0px;*/
/*    font-weight: bold;*/
/*}*/
ul {
    list-style-type: none;
}
.flex_between {
    justify-content: space-between;
}
.flex_between, .flex_right {
    display: flex;
    align-items: center;
}
.all_Fanya_copyright{
    width:100%;
    height:80px;
    background-color:#09194d;
    overflow:hidden;
}
.Fanya_copyright{
    width:1360px;
    overflow:hidden;
    height:80px;
    line-height:80px;
    font-size:14px;
    color:#a5a5a5;
    text-align:center;
}
.Fanya_copyright a{
    font-size:14px;
    color:#a5a5a5;
    text-decoration:none;
}
.Fanya_copyright a:hover{
    text-decoration:underline;
}


.nyrcon{
    /*width:1360px;*/
    /*margin: auto;*/
    padding-top: 30px;
}
.nyrt span {
    display: block;
    font-size: 24px;
    color: #0d41ad;
    height: 24px;
    line-height: 24px;
    padding-left: 20px;
    border-left: 5px solid #155ea3;
}
.banner_jianjie{
    width:1360px;
    height:500px;
    margin:0 auto;
    position:relative;
}
.banner_jianjie_A{
    width:720px;
    height:60px;
    line-height:60px;
    font-size:40px;
    color:#0c2166;
    font-weight:bold;
    position:absolute;
    left:0;
    top:53px;
}
.banner_jianjie_B{
    width:720px;
    height:auto;
    position:absolute;
    left:0;
    top:125px;
    font-size:16px;
    line-height:28px;
    color:#1a1a1a;
}
.banner_jianjie_C{
    width:590px;
    height:510px;
    position:absolute;
    right:0;
    top:8px;
}
.all_width.is_PC{
    position: fixed;
    top: 0px;
}
.bg{
    background: url("../../src/images/背景.jpg") 100% 100% no-repeat;
}
.nyrcon h1.contact-tit {
    display: block;
    font-size: 24px;
    color: #0d41ad;
    height: 24px;
    line-height: 24px;
    padding-left: 20px;
    border-left: 5px solid #155ea3;
    font-weight: normal;
}


.service-details .l-box{
    float: left;
    width: 290px;
    /*overflow: hidden;*/
    height: -moz-calc(100vh - 120px);
    height: -webkit-calc(100vh - 120px);
    height: calc(100vh - 120px);
}
.service-details .l-box .s-plate{
    text-align: left;
    overflow: hidden;
}
.service-details .l-box .s-plate .icon{
    padding-top: 30px;
    margin-bottom: 25px;
}
.service-details .l-box .s-plate .icon img{
    max-width: 34.5%;
}
.service-details .l-box .s-plate .title .t4{
    font-family: "sym";
    color: #222222;
}
.service-details .l-box .s-plate .title .t8{
    margin-top: 5px;
    color: #999999;
    font-family: arial;
}
.service-details .l-box .s-plate .title .btn{
    text-align: left;
    margin-top: 20px;
}
.service-details .l-box .s-plate .title .btn a{
    min-width: 140px;
    max-width: 100%;
    height: 40px;
    line-height: 40px;
    font-family: "syr";
    color: #ffffff;
    background: #ff6a00;
    border-radius: 4px;
    text-align: center;
    padding: 0px 15px;
    overflow: hidden;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: inline-block;
}
.service-details .l-box .d-nav{
    margin-top: 30px;
    background: #fff;
}
.service-details .l-box .d-nav ul li{
    position: relative;
    border-top: #eeeeee solid 1px;
    cursor: pointer;
}
.service-details .l-box .d-nav ul li:before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 3px;
    height: 0;
    background: #3d509f;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.service-details .l-box .d-nav ul li.on:before, .service-details .l-box .d-nav ul li:hover:before{
    height: 100%;
}
.service-details .l-box .d-nav ul li span{
    position: relative;
    display: block;
    width: 100%;
    color: #444444;
    font-size: 16px;
    font-family: "syr";
    line-height: 1.7;
    padding-top: 15px;
    padding-left: 35px;
    padding-bottom: 15px;
    overflow: hidden;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.service-details .l-box .d-nav ul li:hover span, .service-details .l-box .d-nav ul li.on span{
    color: #3d509f;
    background: url(../images/img236.png) center right no-repeat;
    background-size: cover;
    /*filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#ffffff,endcolorstr=rgba(61,80,159,0.3),gradientType=1);
    background:-moz-linear-gradient(left, #ffffff, rgba(61,80,159,0.3));
    background:-webkit-gradient(linear, left top, right top, from(#ffffff), to(rgba(61,80,159,0.3)));
    background:-o-linear-gradient(left, #ffffff, rgba(61,80,159,0.3));*/
}
.service-details .l-box .d-nav ul li span i{
    position: absolute;
    left: 5px;
    top: 24px;
    width: 8px;
    height: 8px;
    background: url(../images/img235.png) center no-repeat;
}
.service-details .r-box{
    float: right;
    width: -moz-calc(100% - 290px);
    width: -webkit-calc(100% - 290px);
    width: calc(100% - 290px);
    border-left: #e5e5e5 solid 1px;
    padding-left: 6.4%;
    /* overflow: hidden; */
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.service-details{
    width: 1400px;
    margin: auto;
}
.all_fnav{
    width:100%;
    padding:0px 0 0 0;
    background-color:#051033;
    overflow:hidden;
}
.Fanya_footer_nav{
    width:1580px;
    height:260px;
}
.Fanya_footer_nav ul{
    margin:0;
    padding:0;
    list-style:none;
}
.Fanya_footer_nav ul li{
    width:355px;
    float:left;
    margin-right:40px;
    border-right:1px solid #1e2848;
    overflow:hidden;
}
.Fanya_footer_nav ul li:last-child{
    border:none;
    margin-right:0;
}
.Fanya_footer_nav_TT{
    width:100%;
    height:49px;
    line-height:44px;
    font-size:20px;
    color:#fff;
}
.Fanya_footer_sub{
    width:100%;
    overflow:hidden;
}
.Fanya_footer_sub dl{
    margin:0;
    padding:0;
    list-style:none;
}
.Fanya_footer_sub dl dt{
    width:100%;
    height:36px;
    line-height:36px;
    float:left;
}
.Fanya_footer_sub dl dt a{
    font-size:16px;
    color:#9d9fa7;
    text-decoration:none;
}
.Fanya_footer_sub dl dt a:hover{
    text-decoration:underline;
    color:#fff;
}

.b{
    color:#fff;
    overflow:hidden;
    border-bottom:1px solid #1e2848;
    margin-bottom: 15px;
}
.hover{
    width: 80%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    padding:80px 10%;
    color: #fff;
    -webkit-transition: .8s;
    -moz-transition: .8s;
    -ms-transition: .8s;
    -o-transition: .8s;
    display: none;
    transition: .8s;
    font-size: 18px;
}
